<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerText="Setup Your Custom Query"
            @continue="continueForm"
            @reset="resetForm"
            ref="tabbedForm"
            details
            :dirty="dirty"
            :fetched="fetched"
            :update="updateQueryJob"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-form="{step}">
                <b-form-group label="Query name" label-for="query-name" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                placeholder="Name"
                                v-model="$v.queryForm.name.$model"
                                id="query-name"
                                type="text"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('queryForm.name', step)"
                                invalid="This field is required"
                                valid="Query name is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Save Query Results" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <b-form-checkbox-group class="query-job-lower-row">
                                <label
                                    class="switch switch-label switch-pill switch-primary switch-show-password"
                                    data-children-count="1"
                                >
                                    <input
                                        class="switch-input"
                                        type="checkbox"
                                        v-model="optionsForm.saveQueryResults"
                                    />
                                    <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                                </label>
                            </b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Table prefix"
                    label-for="table-prefix"
                    :label-cols="2"
                    :horizontal="true"
                    v-if="optionsForm.saveQueryResults"
                >
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                placeholder="Prefix"
                                v-model="$v.optionsForm.prefix.$model"
                                id="table-prefix"
                                type="text"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('optionsForm.prefix', step)"
                                invalid="This field must be between 5 and 20 characters long, contain only letters, numbers and underscores"
                                valid="Prefix is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Write Disposition"
                    label-for="write-disposition"
                    :label-cols="2"
                    :horizontal="true"
                    v-if="optionsForm.saveQueryResults"
                >
                    <b-row>
                        <b-col md="8">
                            <wit-select
                                v-model="$v.optionsForm.writeDisposition.$model"
                                label="name"
                                placeholder="Select write disposition"
                                :options="writeDispositionOptions"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('optionsForm.writeDisposition', step)"
                                invalid="This field is required"
                                valid="Prefix is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Partitioned"
                    label-for="table-partitioned"
                    :label-cols="2"
                    :horizontal="true"
                    v-if="optionsForm.saveQueryResults"
                >
                    <b-row>
                        <b-col md="8">
                            <b-form-checkbox-group class="query-job-lower-row">
                                <label
                                    class="switch switch-label switch-pill switch-primary switch-show-password"
                                    data-children-count="1"
                                >
                                    <input class="switch-input" type="checkbox" v-model="enablePartitioning" />
                                    <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                                </label>
                            </b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    v-if="optionsForm.saveQueryResults && enablePartitioning"
                    label="Partition by"
                    label-for="partition-by"
                    :label-cols="2"
                    :horizontal="true"
                >
                    <b-row class="query-job-lower-row">
                        <b-col md="8">
                            <b-form-radio v-model="partitionCustomField" :value="false">Ingestion Time</b-form-radio>
                            <b-form-radio v-model="partitionCustomField" :value="true">Custom Field </b-form-radio>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    v-if="optionsForm.saveQueryResults && enablePartitioning && partitionCustomField"
                    label=""
                    :label-cols="2"
                    :horizontal="true"
                >
                    <b-row>
                        <b-col md="8">
                            <b-form-input
                                placeholder="Custom Field name"
                                v-model="$v.optionsForm.partitioning.field.$model"
                                type="text"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('optionsForm.partitioning.field', step)"
                                invalid="This field is required"
                                valid="Prefix is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Query" label-for="query" :label-cols="2" :horizontal="true">
                    <b-row>
                        <b-col md="8">
                            <SqlEditor v-model="$v.queryForm.query.$model" />
                        </b-col>
                    </b-row>
                    <b-row id="queryform-btn-row">
                        <b-col md="8">
                            <b-button id="test-query-btn" @click="testQuery" variant="warning">
                                <i class="icon-check"></i> Test
                            </b-button>
                            <Feedback
                                :state="validateRef('queryForm.query', step)"
                                invalid="This field is required"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                    label="Environment Variables"
                    label-for="env-variables"
                    :label-cols="2"
                    :horizontal="true"
                >
                    <b-row class="env-variables-table-row">
                        <b-col md="8">
                            <strong>These variables will be added automatically</strong>
                            <b-table-simple borderless class="env-variables-table">
                                <b-tbody>
                                    <b-tr v-for="variable in envVariables" v-bind:key="variable.name">
                                        <b-td rowspan="3">{{ variable.name }}</b-td>
                                        <b-td rowspan="9">{{ variable.description }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Variables" label-for="variables" :label-cols="2" :horizontal="true">
                    <span v-for="(item, index) in $v.queryForm.variables.$each.$iter" :key="index">
                        <b-row class="variable-row">
                            <b-col md="3">
                                <b-form-input
                                    placeholder="Key"
                                    v-model="$v.queryForm.variables.$each[index].key.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="3">
                                <wit-select
                                    placeholder="Type"
                                    v-model="$v.queryForm.variables.$each[index].type.$model"
                                    :options="variableTypeOptions"
                                ></wit-select>
                            </b-col>
                            <b-col md="3">
                                <b-form-input
                                    placeholder="Value"
                                    v-model="$v.queryForm.variables.$each[index].value.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="1">
                                <b-button @click="queryForm.variables.splice(index, 1)" size="sm" variant="primary">
                                    <i class="fa fa-minus"></i>
                                </b-button>
                            </b-col>
                        </b-row>

                        <Feedback :state="validateVariable(index, step)" invalid="This field is required"></Feedback>
                    </span>
                    <b-row>
                        <b-col md="8">
                            <b-button
                                @click="queryForm.variables.push({key: null, type: null, value: null})"
                                variant="warning"
                            >
                                <i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </template>
        </wit-tabbed-form>

        <WitModal
            v-model="showTestQueryModal"
            title="Query Test Result"
            :variant="dryRunResponse && dryRunResponse.statistics ? 'warning' : 'danger'"
            disableDBlock
            size="lg"
            customClass="dry-run-modal"
        >
            <div role="tablist" class="tabbed-form">
                <b-card no-body class="mb-1">
                    <b-collapse visible role="tabpanel">
                        <b-card v-if="dryRunResponse && dryRunResponse.statistics" no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1 form-step-name" role="tab"
                                >Estimations</b-card-header
                            >
                            <b-collapse visible role="tabpanel">
                                <b-card-body>
                                    <b-form class="step-form">
                                        <p>
                                            Monthly cost (run every hour) :
                                            {{ calculateEstimatedCost(dryRunResponse.statistics.totalBytesProcessed) }}
                                            $
                                        </p>
                                        <p>
                                            Monthly processed (run every hour) :
                                            {{
                                                calculateEstimatedBytesProcessed(
                                                    dryRunResponse.statistics.totalBytesProcessed
                                                )
                                            }}
                                            GB
                                        </p>
                                    </b-form>
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <b-card v-else no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1 form-step-name" role="tab"
                                >Something went wrong :(</b-card-header
                            >
                        </b-card>

                        <json-viewer
                            v-if="dryRunResponse"
                            :value="dryRunResponse"
                            :expand-depth="5"
                            copyable
                            boxed
                            sort
                        ></json-viewer>
                    </b-collapse>
                </b-card>
            </div>
            <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
        </WitModal>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import cloneDeep from 'lodash.clonedeep'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitModal from '@/components/Modals/WitModal.vue'
import SqlEditor from '@/components/SqlEditor.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

export default {
    components: {
        Feedback,
        Loading,
        JsonViewer,
        WitModal,
        SqlEditor,
        WitTabbedForm,
    },
    mixins: [formMixin, validationMixin],
    validations: {
        queryForm: {
            name: {required},
            query: {required},
            variables: {
                $each: {
                    key: {required},
                    value: {required},
                    type: {required},
                },
            },
        },
        optionsForm: {
            prefix: {
                valid: function(value) {
                    if (!this.optionsForm.saveQueryResults) return true

                    return value && value.length >= 1 && value.length <= 40 && /^[a-zA-Z0-9_]*$/.test(value)
                },
            },
            writeDisposition: {
                required: requiredIf(function() {
                    return this.optionsForm.saveQueryResults
                }),
            },
            partitioning: {
                field: {
                    required: requiredIf(function() {
                        return this.optionsForm.saveQueryResults && this.enablePartitioning && this.partitionCustomField
                    }),
                },
                type: {
                    required: requiredIf(function() {
                        return this.optionsForm.saveQueryResults
                    }),
                },
            },
        },
    },
    data() {
        return {
            formId: 'query-job-update-form',
            updateRedirect: '/reports/queryjobs?queryJobUpdated=true',
            cannotContinue: true,
            queryForm: {
                name: null,
                query: '',
                variables: [],
            },
            currentQueryForm: {},
            optionsForm: {
                saveQueryResults: true,
                prefix: '',
                writeDisposition: null,
                partitioning: {
                    field: '',
                    type: 'DAY',
                },
            },
            currentOptionsForm: {},
            enablePartitioning: false,
            currentEnablePartitioning: false,
            partitionCustomField: false,
            currentPartitionCustomField: false,
            writeDispositionOptions: [
                {
                    name: 'Overwrite if table/partition exists',
                    disposition: 'WRITE_TRUNCATE',
                },
                {
                    name: 'Append to table/partitions',
                    disposition: 'WRITE_APPEND',
                },
                {
                    name: 'Write only to empty table/partitions',
                    disposition: 'WRITE_EMPTY',
                },
            ],
            variableTypeOptions: ['Numeric', 'String'],
            envVariables: [{name: 'RUN_DATE', description: 'It is the date the job is running for'}],
            showTestQueryModal: false,
            dryRunResponse: null,
            queryJobError: {
                state: null,
                message: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            loading: 'loading/state',
            activeProject: 'project/active',
        }),
        steps() {
            return [
                {
                    name: 'Query Settings',
                    invalid: this.$v.$invalid,
                },
            ]
        },
        dirty() {
            const queryFormDirty = filterDirty(this.queryForm, this.currentQueryForm)
            const optionsFormDirty = filterDirty(this.optionsForm, this.currentOptionsForm)

            const enablePartitioningDirty = filterDirty(
                {enablePartitioning: this.enablePartitioning},
                {enablePartitioning: this.currentEnablePartitioning}
            )
            const partitionCustomFieldDirty = filterDirty(
                {partitionCustomField: this.partitionCustomField},
                {partitionCustomField: this.currentPartitionCustomField}
            )

            return (
                Object.keys({
                    ...queryFormDirty,
                    ...optionsFormDirty,
                    ...enablePartitioningDirty,
                    ...partitionCustomFieldDirty,
                }).length > 0
            )
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Fetching Your Query Job...`)
        await this.fetchQueryJob()
        this.fetched = true
        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        fetchQueryJob() {
            return new Promise(async resolve => {
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/report/queryJob/${this.$route.params.id}`
                )
                this.queryJob = response.data.data

                this.queryForm = {
                    name: this.queryJob.name,
                    query: this.queryJob.query,
                    variables: this.queryJob.variables,
                }

                this.optionsForm = {
                    prefix: this.queryJob.options.prefix,
                    writeDisposition: this.writeDispositionOptions.find(
                        el => el.disposition === this.queryJob.options.writeDisposition
                    ),
                    partitioning: {
                        field: this.queryJob.options.partitioning ? this.queryJob.options.partitioning.field : '',
                        type: 'DAY',
                    },
                    saveQueryResults: this.queryJob.options.saveQueryResults,
                }
                this.enablePartitioning =
                    this.queryJob.options.partitioning && Object.keys(this.queryJob.options.partitioning).length > 0

                this.partitionCustomField = this.enablePartitioning
                    ? Boolean(this.queryJob.options.partitioning.field)
                    : false

                this.currentQueryForm = cloneDeep(this.queryForm)
                this.currentOptionsForm = cloneDeep(this.optionsForm)
                this.currentEnablePartitioning = this.enablePartitioning
                this.currentPartitionCustomField = this.partitionCustomField

                resolve(true)
            })
        },

        testQuery() {
            this.$store.commit('loading/PROCESSING', `Testing Your Query...`)

            const data = {
                query: this.queryForm.query,
                variables: this.queryForm.variables,
                projectId: this.activeProject.id,
            }

            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/report/queryJob/dryRun`, data)
                .then(response => {
                    const {
                        data: {data},
                    } = response

                    this.dryRunResponse = data
                    this.$store.commit('loading/PROCESSED')

                    this.showTestQueryModal = true
                })
                .catch(error => {
                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(error, 'Could not test query job')
                })
        },

        async updateQueryJob() {
            if (!this.$v.$invalid) {
                const {partitioning, writeDisposition, prefix, saveQueryResults} = this.optionsForm

                const options = {
                    prefix: saveQueryResults && prefix ? prefix : undefined,
                    writeDisposition: saveQueryResults && writeDisposition ? writeDisposition.disposition : undefined,
                    saveQueryResults,
                }

                if (saveQueryResults && this.enablePartitioning) {
                    options.partitioning = {
                        field: this.partitionCustomField ? partitioning.field : undefined,
                        type: 'DAY',
                    }
                }

                const data = {
                    ...this.queryForm,
                    options,
                }

                this.$store.commit('loading/PROCESSING', `Updating Your Query Job`)
                try {
                    await this.axios.patch(
                        `${process.env.VUE_APP_NODE_API_HOST}/report/queryJob/${this.$route.params.id}`,
                        data
                    )

                    this.queryJobError = {}
                    this.currentQueryForm = cloneDeep(this.queryForm)
                    this.currentOptionsForm = cloneDeep(this.optionsForm)
                    this.currentEnablePartitioning = this.enablePartitioning
                    this.currentPartitionCustomField = this.partitionCustomField
                    this.$v.$reset()

                    this.$store.commit('loading/PROCESSED')
                } catch (exception) {
                    this.$store.commit('loading/PROCESSED')
                    this.queryJobError.state = false
                    this.queryJobError.message = exception.response.data.additionalInfo
                    this.$errorHandler.report(exception, 'Could not update form')
                }
            }
        },

        calculateEstimatedCost(bytesProcessed) {
            return ((bytesProcessed / (1024 * 1024 * 1024 * 1024)) * 24 * 30 * 5).toFixed(2)
        },

        calculateEstimatedBytesProcessed(bytesProcessed) {
            return ((bytesProcessed / (1024 * 1024 * 1024)) * 24 * 30).toFixed(2)
        },

        validateVariable(index, step) {
            const {key, value} = this.$v.queryForm.variables.$each[index]
            if (step.checked) return !key.$invalid && !value.$invalid
            return key.$dirty && value.$dirty ? !key.$invalid && !value.$invalid : null
        },
    },
}
</script>

<style lang="scss">
#queryform-btn-row {
    margin-top: 0.5rem;
    .btn {
        margin-right: 0.3rem;
    }
}
.variable-row {
    margin-bottom: 5px;
    input.form-control {
        min-height: 43px;
    }
}

.env-variables-table-row {
    margin-top: 5px;
}

.query-job-lower-row {
    margin-top: 7px;
}
</style>
